.dropdown {
    display: none;
}
.dropdown.show {
    display: block;
}
.menus {
    display: flex;
    margin-bottom: 0px;
    padding-left: 0px;
    justify-content: center;
}
li.menu-items {
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 10px 15px;
}
.dropdown .menu-items {
    align-items: center;
    justify-content: center;
    list-style-type: none;
    /* padding: 0px; */
    padding: 7px 1px 7px 5px;
}
.menu-items a {
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    text-decoration: auto!important;
}
.menu-items a.active {
    color: #3377FF;
}
.main_subs {
    width: 100%;
    position: fixed;
    top: 89px;
    /* box-shadow: 0px 4px 25px rgb(0 0 0 / 7%); */
    box-shadow: 0px 1px 6px rgb(0 0 0 / 7%);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 9;
    background-color: #fff;
}

.menu-items .dropdown {
    position: fixed;
    z-index: 9999999999;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 16%);
    padding-left: 0px;
    margin-top: 10px;
}
.menu-items .dropdown a {
    /* padding: 10px 10px; */
    padding: 8px 50px 8px 10px;
}
.dropdown .menu-items:hover {
    background-color: #0d6efd;
}
.dropdown .menu-items:hover a {
    color:#ffffff;
}
.dropdown .menu-items:hover {
    background-color: #3377fe;
    color:#fff;
}