.CardInnerDiv_2 {
    padding: 20px 10px 0px 10px !important;
    width: -webkit-fill-available;
  }

.cardDiv {
    
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;

}
