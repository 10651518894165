/* Responsive design for Mobile  */
@media (min-width: 280px) and (max-width:1000px) and (orientation: portrait) {
  .Time_Table {
    /* display: contents; */
    display: none;
  }

  
  .chartteachmobi{
    display: block;
  }

  .tableText {
    display: contents;
  }

  .mobiCall {
    margin-top: 20px !important;
  }

  .Time_Table_BirthDay {
    display: none;
  }

  .WEBSHOW {
    display: none;
  }

  .mob_web_display_none {
    display: none!important;
  }

  .MbileShow {
    display: contents;
  }

  .paraimg_back {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(4, 17, 31, 0) 50%, #04111F 100%) !important;
    width: 100%;
    overflow: hidden;
    height: 245px;
  }

  .imgteach {
    width: 355px;
    height: 200px;
    border-radius: 5px;
    margin: 0px 0px !important;
}

.padtexhres{
  padding-top: 15px;
}

.padtexhres1{
  padding-top: 15px;
}

  .appbuild{
    margin: 20px 20px !important;
  }

  .Card_Teacher_classNotice {
    width: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 7%);
    border-radius: 16px;
    margin: 0px 0px 0px 20px;
    padding: 10px;
}

  .myattendcardteach {
    height: 800px !important;
    background-color: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 7%);
    border-radius: 5px;
    overflow-y: scroll;
    padding: 15px 15px;
    margin: 10px 10px;
}

.rowcharblue {
  width: 85%;
  height: 10px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  border-radius: 30px;
}


.rowcharorange{
  width: 90%;
  height: 10px;
  background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
  border-radius: 30px;
  }

.rowcharyello{
  width: 70%;
  height: 10px;
  background: linear-gradient(180deg, #FFE991 0%, #F2C925 100%);
  border-radius: 30px;
  }


.Chartbackjioblue {
  width: 80%;
  height: 10px;
  background: #E3EDFF;
  border-radius: 30px;
}

.Chartbackjiorange {
  width: 80%;
  height: 10px;
  background: #FEC7B9;
  border-radius: 30px;
  margin-left: 10px;
}

.Chartbackjioyeloo {
  width: 80%;
  height: 10px;
  background: #FFEDA6;
  border-radius: 30px;
}

.Chartbackjio {
  width: 80%;
  height: 10px;
  background: #A8EACD;
  border-radius: 30px;
  margin-left: 10px;
}

.rowchar {
  width: 80%;
  height: 10px;
  background: linear-gradient(180deg, #13DA84 0%, #189C63 100%);
  border-radius: 30px;
}

.Mothachart {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.charttaech {
  margin: 10px 20px 0px 20px !important;
}

.myatenteacher {
  margin: 20px 20px 0px 20px !important;
}


  .chartSecrty {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin: 0px 0px;
}

.classdfgghjio {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #ADB9C7;
}



  .teachPROGRESSweb{
    display: none;
  }

  .padfhjikomobi{
    margin: 50px 5px;
  }

  .dateaten{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #66747F;
  padding-top: 6px !important;
  padding-left: 7px !important;
  }

  .Time_Tablemobi {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0px !important;
    color: #1B252F;
  }

  .Achive_Title {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 30px 0px 0px 10px;
  }


  .birthTitle {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 10px 20px;
  }

  .EvntTitle {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 20px 0px;
  }

  .MobiExaResu {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 20px 0px;
  }

  .add_event {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #3377FF;
    margin: 20px 10px;
  }


  .exschDahmobi {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 10px 10px;
  }

  .AssigTimeMObi {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 20px 10px;
  }

  .MyAttenMobi {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 5px 10px 5px 5px;
    padding-top: 10px;
  }

  .calssTitleMobi {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 20px 10px;
  }

  .schollTitle {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B252F;
    margin: 20px 10px;
  }

  .Time_Apr {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px;
  }

  /* .Apr_22 {
    margin: 10px;
  } */

  .Day_Date {
    display: none;
  }

  .Time {
    display: none;
  }

  .crosstab {
    display: none;
  }

  .dgmobi {
    display: flex;
    flex-direction: column;
  }

  .dgmonbii {
    display: block;
    display: flex;
    justify-content: space-between;
  }


  .AM_Time {
    display: contents;
  }

  .Gap_Time {
    gap: 10px;
  }

  .AM_Timemobi {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
  }

  .DateWise_tableData {
    display: flex;
    padding-top: 0px;
    height: auto;
  }

  /* .timeTable_Home_scrn {
    display: flex;
    padding-top: 0px;
    height: auto;
  } */

  .English_Time {
    width: 95%;
    height: auto;
    border: 1px solid #EEF2F5;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px 1px 1px 3px;
    padding: 8px 15px 10px 15px;
    margin-left: 2%;
    margin-top: 5px;
    margin-right: 15px;
  }
/*   
  .BotfilImg{
    position: absolute;
    bottom: 30px;
  } */
  .kampus_Main_Card {
    padding: 0px 5px;
  }


  .Login_logoImg {
    width: 60%!important;
  }
  

  #loginform {
    width: 100%;
  }

  .D_FLex_Unit {
    display: flex;
    flex-direction: column;
  }

  .Unit_supplementarty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #2A004A;
    width: 200px;
    height: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
  }

  .Unit_supplementartyQ {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #2A004A;
    width: 200px;
    height: 2%;
    overflow: hidden;
    text-overflow: ellipsis !important;
    border: none;
  }


  .Cath_F_S {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    color: #35414D;
  }

  .minuMsrgi {
    margin-top: -10px;
  }

  .padTopMari {
    padding-top: 20px;
  }

  .Background_ColorMobi {
    display: block;
    height: auto;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 10px;
  }


  .Background_ColorMobi::-webkit-scrollbar {
    display: none;
  }

  .Background_ColorMobi {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }


  .Background_Color {
    height: auto;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 10px;
    box-shadow: none;
    background: none;
    border: none;
  }

  .column_Box {
    padding: 0px;
  }

  .padkamin {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .containerHRFlow {
    border-bottom: 1px solid #E6ECF1;
    clear: both;
    width: 90%;
    margin: 0px 20px;
  }

  /* first end */


  .schoolScrollMobi {
    display: block;
    padding-bottom: 20px;
  }

  .desktabScholScrol {
    display: none;
  }

  .examinmghj {
    height: 245px !important;
    width: 100%;
    min-height: 245px !important;
    border-radius: 5px !important;
  }

  .slidecarosoul {
    /* padding-top: 20px !important; */
    padding-bottom: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .paraimg_fir {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #B8C2CC;
    position: absolute;
    top: 150px;
    left: 5px;
    z-index: 99999;
  }

  .paraimg_sec {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    color: #FFFFFF;
    position: absolute;
    top: 170px;
    left: 5px;
    z-index: 99999;

  }

  .paraimg_thi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #FFD426;
    position: absolute;
    top: 195px;
    left: 5px;
    z-index: 99999;
  }

/* 
  .css-1m9128y {
    position: absolute;
    top: 213px;
    width: 100%;
    z-index: 99;
  } */

  /* second end */

  .viewall_class {
    display: block;
    margin: 20px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #3377FF;
  }

  .MariOExResul {
    margin-top: 20px;
  }

  .classNotice {
    display: none;
  }

  .classNoticeMobi {
    display: block;
    margin: 0px 20px;
  }

  .datebelownotice {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #1B252F;
    padding-top: 10px;
  }

  .datenotice {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #66747F;
  }

  /* third end */

  .minusMarilef2 {
    margin: 13px 5px 0px 5px;
  }

  .digcolumnRownaki {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .columnnaki {
    display: none;
  }

  .myattendcard {
    display: none;
  }

  .myattendcardMobi {
    display: block;
    padding-bottom: 25px;
  }


  .barboriee1 {
    width: 12px;
    height: 171px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee2 {
    width: 12px;
    height: 121px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee3 {
    width: 12px;
    height: 156px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    border-radius: 30px 30px 0px 0px;
    opacity: 0.2;
  }

  .barboriee4 {
    width: 12px;
    height: 208px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee5 {
    width: 12px;
    height: 171px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee6 {
    width: 12px;
    height: 193px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee7 {
    width: 12px;
    height: 164px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee8 {
    width: 12px;
    height: 140px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 1;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee9 {
    width: 12px;
    height: 100px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee10 {
    width: 12px;
    height: 100px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee11 {
    width: 12px;
    height: 100px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }

  .barboriee12 {
    width: 12px;
    height: 150px;
    background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
    opacity: 0.2;
    border-radius: 30px 30px 0px 0px;
  }


  .borderbottmobi {
    box-sizing: border-box;
    width: 100%;
    /* height: 70%; */
    background-color: #FAFAFA;
    border: 1px solid #EEF2F5;
    border-radius: 8px;
    margin-top: 3%;
    margin-left: 0%;
  }

  /* fourth end */

  .cardassignmnetsss {
    display: none;
  }

  .cardassignmnetsssMobi {
    display: block;
    margin: 0px 20px;
  }

  .assign_dateMobi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #66747F;
    line-height: 150%;
  }

  .mathsassignMobi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    gap: 10px;
    width: 50px;
    height: 23px;
    background: #EEFBF5;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    color: #06C270;
    margin: 0px 5px;
  }

  .kildateMobi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #1B252F;
  }

  .maritb {
    margin: 10px 0px;
  }

  .examcard {
    display: none;
  }


  .QuestionType_Options_list {
    display: none;
  }

  .examcardMobi {
    display: block;
    height: auto;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .examcardMobi::-webkit-scrollbar {
    display: none;
  }

  .examcardMobi {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .bodrigh {
    height: auto;
    margin-top: 5px;
  }

  .orderB {
    border: 1px solid #E6ECF1;
    transform: rotate(90deg);
    width: 40px;
    height: 0px;
    margin-right: 20px;
  }


  .engdateexa {
    width: 74px;
    height: 74px;
    background: #EEF2F5;
    opacity: 0.7;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    margin-right: 5%;
  }

  .mobisubunit {
    margin-top: 6%;
    margin-right: -15px;
  }

  .engdatefirstmobi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    color: #1B252F;
  }

  .engdatesecmobi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    color: #66747F;
  }

  .engsubmobi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #1B252F;
  }

  .engsubdownmobi {
    width: 149px;
    height: 36px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #66747F;

  }

  .totuni_re_Mobi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #4C88FF;
    margin-top: 20px;
  }

  .totuni_re {
    display: none;
  }

  .ex_recard {
    display: block;
    height: auto;
    box-shadow: none !important;
    background: none !important;
    border: none;
    margin: 0px 25px;
    padding-bottom: 30px;
    padding: 5px;
  }

  .flerojubet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 10px;
  }

  .totex_re {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #ADB9C7;
  }

  .theeigex_re {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #1B252F;

  }

  .fivex_re {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #1B252F;

  }

  .gapExresu {
    gap: 8px;
  }

  .lanex_re {
    width: 42px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #8F9CAA;
  }


  .numex_re {
    margin-left: 20px;
    margin-top: -3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: right;
    color: #1B252F;
  }


  .eventcard {
    height: auto;
    width: 493px;
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 16px;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0px 10px;
  }

  .eventcardDD {
    width: 100%;
    overflow-x: auto;
  }

  .eventcardDD2 {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
  }

  .eventcardDD::-webkit-scrollbar {
    display: none;
  }

  .eventcardDD {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .eventcardDD2::-webkit-scrollbar {
    display: none;
  }

  .eventcardDD2 {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .eventcard::-webkit-scrollbar {
    display: none;
  }

  .eventcard {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }


  .titleevent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #1B252F;
  }


  .Eng {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
  }


  .Tilelelele {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #1B252F;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Tilelelelesd {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #1B252F;
    white-space: normal;
    overflow: auto;
  }


  .Unit_supplementarty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #B8C2CC;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
  }

  .Unit_supplementartyMod {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #B8C2CC;
  }

  .stylemyCarsou {
    height: 400px;
    width: 300px;
    text-align: center;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-family: sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }


  .achiveimg {
    border-radius: 50%;
    margin-left: 32%;
  }



  .lementstyle {
    position: relative;
    height: 450px;
    width: 100%;
    display: flex;
    background: none;
    border-radius: 10px;
    flex: 1 1 0%;
  }


  .birthCard {
    height: auto;
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 16px;
    padding: 0px 20px 40px 20px !important;    
  }

  .upcomeBirth {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #1B252F;
  }

  .imhtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #35414D;
  }

  .imhtext2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #8F9CAA;
  }

  .wishbith2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #1B252F;
  }

  .WebShownDesign {
    display: none;
  }

  .wishbith {
    /* flex-direction: row;
align-items: center;
justify-content: center; */
    background: #3377FF;
    border-radius: 30px;
    /* text-align: center;
display: flex; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 30px;
    gap: 10px;
  }

  .senwishText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1;
    color: #FFFFFF;
  }

  .Mobile_ShowDesign {
    display: contents;
  }

  .FooterAlign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 20px 0px;
    gap: 8px;
  }

  .float-right {
    box-sizing: border-box;
    /* position: fixed; */
    width: 48px;
    height: 48px;
    right: 20px;
    bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.12);
    border-radius: 1000px;
    padding-left: 10px;
    padding-top: 10px;
    z-index: 9999999999;
    /* display: none; */
  }

  .Textbirth {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

  }

  .Meet_Time_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #35414D;
  }

  .Mon_Apr {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #1B252F;
  }

  .P_Unit_S {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #66747F;
  }

  .Session_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #3377FF;
  }

}

/* Responsive design for Mobile  */


/* Responsive design for Tablet  */
@media (min-width: 481px) and (max-width: 1200px) and (orientation: landscape) {
  .Time_Table {
    display: block;
  }

  .tabletv_Qi {
    padding-top: 20px;
  }

  .borderbottmobi {
    margin-top: 2%;
    width: 96%;
    margin-left: 2%;
  }

  .MyAttenMobi {
    display: none;
  }

  .Time_Tablemobi {
    display: none;
  }

  .AM_Timemobi {
    display: none;
  }


  .classNotice {
    height: 582px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    overflow: auto;
    margin-top: 12px;
    padding: 0px 12px 0px 12px;
  }

  
.column_Boxx {
  padding: 0px;
}


  .classNotice::-webkit-scrollbar {
    display: none;
  }

  .classNotice {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }


  .datenotice {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: #66747F;
  }


  .datebelownotice {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #1B252F;
    padding-top: 10px;
    padding-left: 0px;
    letter-spacing: 0.8;
  }

  .classPadding {
    padding: 15px 0px;
  }

  .padAbsent17 {
    padding-left: 20px;
  }

  .padlef17 {
    padding-left: 5px;
  }

  .columnnaki {
    display: contents;
  }


  .dateaten {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #66747F;
    padding-top: 12px !important;
  }


  .attenpara {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #ADB9C7;
  }


  .attennumber {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #1B252F;
  }

/* 
  .css-1m9128y {
    position: absolute;
    top: 87%;
    left: 40%;
    width: 100%;
    z-index: 99999;
  } */


  .paraimg_fir {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #B8C2CC;
    position: absolute;
    top: 215px;
    left: 20px;
    z-index: 999999;
  }

  .paraimg_sec {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    position: absolute;
    top: 240px;
    left: 20px;
    z-index: 999999;

  }

  .paraimg_thi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #FFD426;
    position: absolute;
    top: 270px;
    width: 100%;
    height: 10%;
    z-index: 999999;
    left: 20px;
  }

  .paraimg_back {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(4, 17, 31, 0) 10%, #04111F 100%) !important;
    width: 100%;
    overflow: hidden;
    height: 245px;
  }

  .examinmghj {
    width: 100%;
    height: 345px;
    min-height: 345px;
    border-radius: 5px;
  }

  .slidecarosoul {
    overflow: hidden !important;
    border-radius: 5px !important;
  }


  .cardassignmnetsss {
    box-sizing: border-box;
    height: 612px;
    background: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    overflow-y: auto;
    padding: 0px 20px;
  }


  .cardassignmnetsss::-webkit-scrollbar {
    display: none;
  }

  .cardassignmnetsss {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }


  .assign_date {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: -50px;
    color: #66747F;
    letter-spacing: 1;
  }

  .kildate {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #1B252F;
    letter-spacing: 1;
    margin-top: -12px;
  }


  .mathsassign {
    width: 100%;
    height: 30px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #06C270;
    background: #EEFBF5;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: -8px;
    padding-right: 10px;
  }


  .lementstyle {
    position: relative;
    height: 400px;
    width: 100%;
    display: flex;
    background: rgb(227, 237, 255);
    border-radius: 5px;
    flex: 1 1 0%;
  }

  .stylemyCarsou {
    height: 100%;
    width: 300px;
    padding-top: 20px;
    text-align: center;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-family: sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 10px;
    box-sizing: border-box;
  }


  .engdateexa {
    width: 104px;
    height: 64px;
    background: #EEF2F5;
    opacity: 1;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
  }


  .engsub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #1B252F;
  }

  .engsubdown {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    color: #ADB9C7;
    margin-top: 5px;
  }

  .KpostIf_IyQ {
    display: none;
  }


  .examcard {
    box-sizing: border-box;
    height: 612px;
    background: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    overflow-y: auto;
    padding: 10px;
  }


  .examcard::-webkit-scrollbar {
    display: none;
  }

  .examcard {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }


  .Eng {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: black;
    letter-spacing: 1;
  }


  .Tilelelele {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #1B252F;
    letter-spacing: 1;
  }


  .Unit_supplementarty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #2A004A;
    letter-spacing: 1;
    white-space: nowrap;
    /* width: 250px; */
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
  }


  .eventcard {
    box-sizing: border-box;
    height: 430px;
    background: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    overflow-y: scroll;
  }

  .eventcard::-webkit-scrollbar {
    display: none;
  }

  .eventcard {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ex_recard {
    box-sizing: border-box;
    height: 430px;
    background: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    /* margin: 20px 0px 0px 0px; */
    padding: 20px 10px 0px 10px;
  }

  .gapExresu {
    padding: 0px 10px;
  }


  .totex_re {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #ADB9C7;
  }

  .totuni_re {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    color: #4C88FF;
  }

  .theeigex_re {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #1B252F;
  }

  .fivex_re {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: #1B252F;
  }




  .lanex_re {
    width: 49px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #8F9CAA;
  }


  .coverflexonejai {
    width: 50%;
    background-color: #E5E5E5;
    border-radius: 50px;
    height: 8px;
    margin-left: 10px;
    margin-top: 6px;
  }


  .titleevent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    padding-top: 20px;
    line-height: 150%;
    color: #1B252F;
  }

  
.filteryuio{
  top: 20%;
  left: 68%;  
}

  .birthCard {
    height: 650px;
    width: 100%;
    /* margin-left: -15px; */
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    overflow-y: auto;
  }


  .birthCard::-webkit-scrollbar {
    display: none;
  }

  .birthCard {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .add_event {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #3377FF;
    margin-right: 10px;
  }


  .numex_re {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: right;
    margin-left: 20px;
    margin-top: -3px;
    color: #1B252F;
  }



  .MariOExResul {
    margin: 20px 0px 0px 0px !important;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
  }


  .coverflexonejai {
    width: 35%;
    background-color: #E5E5E5;
    border-radius: 50px;
    height: 8px;
    margin-left: 10px;
    margin-top: 6px;
  }


  .Time_Table_BirthDay {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1B252F;
    letter-spacing: 1;
    margin-top: 20px;
    /* margin-left: -15px; */
  }


  .Background_Color {
    display: block;
    height: 580px;
    background-color: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 7%);
    border-radius: 5px;
    overflow-y: auto;
  }

  .Background_Color::-webkit-scrollbar {
    display: none;
  }

  .Background_Color {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }


  .imhtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #1B252F;
  }


  .wishbith2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    margin-top: 12px;
    color: #1B252F;
  }

  .WebShownDesign {
    display: block;
  }


  .footpower {
    width: 68px;
    height: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #8F9CAA;
  }

  .footyou {
    width: 81px;
    height: 16px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #3377FF;
  }


  .titleimg {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #1B252F;
  }


  .pricelisty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #66747F;
    text-transform: capitalize;
  }

  .padd2022 {
    padding-top: 12px;
  }
  
  
.column_Box {
  padding: 5px;
}

.column_Boxx {
  padding: 0px !important;
}


.allfoot{
  width: 149px;
  height: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #ADB9C7;
  padding-top: 0px;
  }
  
  .paddPower{
    padding-top: 7px;
  }

  .Videocam_Size {
    width: 20px;
    height: 20px;
  }

}

/* android tab */
@media (min-width: 481px) and (max-width: 980px) and (orientation: landscape) {
  .stylemyCarsou {
    height: 100%;
    width: 230px;
    padding-top: 20px;
    text-align: center;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-family: sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 10px;
    box-sizing: border-box;
  }

  
.Time_Table {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1B252F;
  letter-spacing: 1;
}


.datenotice{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 150%;
color: #66747F;
gap: -50px;
}

.datebelownotice{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #1B252F;
}


.attenpara{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 150%;
color: #ADB9C7;
}


.attennumber{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 120%;
color: #1B252F;
}

.paraNumbwer{
  padding-left: 40px;
}


.dateaten{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px !important;
line-height: 150%;
text-align: right;
color: #66747F;
padding-top: 15px !important;
padding-left: 7px !important;
}


.kildate{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 120%;
color: #1B252F;
}


.assign_date{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 150%;
  color: #66747F;
}


.mathsassign{
  width: auto;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 150%;
  border-radius: 5px;
  padding: 10px;
  }

  
.engdatefirst{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 120%;
color: #1B252F;
}


.engdatesec{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 8px;
line-height: 150%;
color: #66747F;
}


.engsub{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 120%;
color: #1B252F;
}


.engsubdown{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #ADB9C7;
  margin-top: 5px;
  }


  .titleimg{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #1B252F;
  text-transform: capitalize;
  }

  
.pricelisty{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
text-align: center;
color: #66747F;
text-transform: capitalize;
}

.add_event{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #3377FF;
margin-bottom: 7px;
}


.titleevent{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px !important;
line-height: 150%;
color: #1B252F;
}


.Eng {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: black;
}


.Unit_supplementartyQ {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  color: #2A004A;  
}


.totex_re{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 150%;
color: #ADB9C7;
}


.theeigex_re{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #1B252F;
}


.fivex_re{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #1B252F;
}


.Time_Table_BirthDay {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1B252F;
  letter-spacing: 1;
  margin-top: 25px;
}


.Textbirth{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 120%;
background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}


.imhtext{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: #1B252F;
}


.imhtext2{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 150%;
color: #8F9CAA;
}


.wishbith{
  gap: 10px;
  color: #fff;
  height: 34px;
  background: #3377FF;
  border-radius: 30px;
  margin-top: 5px;
  padding: 4px 20px 0px 20px;
  font-size: 12px;
  }



  .classNotice{
    height: 580px;
    background-color: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    overflow: auto;
    margin-top: 23px;
    padding: 0px 15px 0px 15px;
  }

  
.birthCard{
  height: 643px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 30px;
}


}

/* android tab */



.classNotice::-webkit-scrollbar {
  display: none;
}

.classNotice {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.dateaten{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 150%;
text-align: right;
color: #66747F;
padding-top: 10px !important;
padding-left: 7px !important;
}

/* Responsive design for Tablet  */


/* Responsive design for Timetable css start here (Mobile)*/

@media (min-width:281px) and (max-width:480px) and (orientation:portrait) {

    /* For Day style css  start here */
    .Time_Table {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #1B252F;
    }
  
    .Apr_22 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #66747F;
      margin-bottom: 0px;
    }
  
    .Header_Dashboard {
      /* display: none; */
    }

    .M_M {
      margin-bottom: 0px !important;
    }

    .M_T_5px {
      margin-top: 5px;
    }
  
    .Time_Apr {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .Switch_Day_Week {
      padding: 0px;
      width: 140px;
      height: 31px;
      border-radius: 30px;
      cursor: pointer;
    }
  
    .Switch_Day {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 12px;
      gap: 10px;
      width: 82px;
      height: 30px;
      border-radius: 30px;
    }
  
    .M_D_W {
      display:flex;
      flex-direction:row;
      align-items:center;
    }
  
    .Day_Date {
      margin: 20px 0px 0px 0px;
      text-align: center;
      padding: 0px 15px;
      /* display: contents; */
    }

    .tableVTxt {
      display: contents;
    }
  
    .Day {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #ADB9C7;
    }
  
    .Date {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: #ADB9C7;
    }
  
    .Tue_Day {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #1B252F;
    }
  
    .Tue_Date {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      background-color: #3377fe;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 4.5px;
      display: flex;
      align-items: center;
    }
  
    .B_Bottom {
      border-bottom: none;
    }
  
    .Time {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #ADB9C7;
      margin-top: 0px;
    }
  

  
    .Eng {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }
  
    .AM_Time {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      margin-left: 10px;
    }
  
    .Row_Reverse {
      display:flex;
      flex-direction:row-reverse;
      align-Items:center;
    }
  
    .Video_Icon_Size {
      font-size: 20px;
    }
  
    .Unit_supplementarty {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
    }
  
    .D_FLex_Unit {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    .Cath_F_S {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      color: #35414D;
      margin-left: 0px;
    }
  
    .F_Gap {
      display:flex;
      flex-direction:row;
      align-items:center;
      gap:5px;
    }
  
    .Padding_Time {
      padding:25px 0px 0px 0px;
    }
    /* For Day style css end here */
  
  

    /* For Week style css start here */
    .M_D_W {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
  
    .Flex_Row_Period {
      display:none;
    }
  
    .Display_Flex {
      display: flex;
      flex-direction: column;
      align-items:flex-start;
      gap:10px;
    }
  
    .D_D_M {
      display: flex;
      flex-direction: row;
      padding: 0px 10px;
    }
  
    .Day_Month {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #1B252F;
      width: 60px;
    }

    .WeekDate {
      width: max-content!important;
    }
  
    .F_J_C {
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      overflow-x: auto;
      gap:10px;
      width:100%;
      white-space: nowrap;
      padding: 0px 10px;
      background-color: #FFFFFF;
    }
  
    .F_J_C::-webkit-scrollbar {
      display: none;
    }
  
    .F_J_C {
      -ms-overflow-style: none; 
      scrollbar-width: none; 
    }
  
    .Eng_Video {
      min-width: 65%;
    }
  
    .Mat_Video {
      min-width: 65%;
    }
  
    .Phy_Video {
      min-width: 65%;   
    }
  
    .Bot_Video {
      min-width: 65%;
    }
  
    .Zoo_Video {
      min-width: 65%;
    }
  
    .Tam_Video {
      min-width: 65%;
    }
  
    .Che_Video {
      min-width: 65%;
    }
  
    .Web_Display_None {
      display:contents;
     }
    
    .Week_User_Img {
     display:flex;
     align-items:center ;
     gap: 10px;
    }
    
    .Col_Gap {
      display:flex;
      flex-direction:column;
      gap:8px;
      margin-top:10px;
      margin-bottom: 10px;
    }
  
    .Pad_Px {
      padding:25px 0px 10px 0px;
    }
  
    .Web_Cama_display_None {
      display:contents;
    }
    /* For Week style css start here */

  
    .Meet_Time_Font {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: #35414D;
    }
  
    .Mon_Apr {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #1B252F;
    }
  
    .P_Unit_S {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 14px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #66747F;
    }
  
    .Session_2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #3377FF;
    }
  
    .Name_Nikil {
      display:none
    }
  
    .Class_12_A {
      display: none;
    }
  
    .M_Right {
      margin-right: 10px;
    }
  
    .mob-display-none {
      display: none;
    }
  
    .Notify_Design {
      position: absolute;
      right: 0;
      width: 100vw;
      height:100vh;
      z-Index: 1;
      padding:0px 0px 0px 0px;
      border-radius: 0px;
      background-Color: #E5E5E5;
      border: 1px solid #E6ECF1;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
      overflow-x:auto;
      top: 0;
      position: fixed;
      left: 0;
      z-index: 99999999999999;
    }

    .Padding {
      padding:3px 10px;
      /* margin-top: 40px; */
    }

    .Padding_Mar {
      padding:5px 10px;
      margin-top: 50px;
    }

    .None_Content {
      display: none;
    }

    .D_Flex{
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }
  
    .Display_Notify_None {
      display: contents;
    }
  
    .D_Flex_1 {
      margin-top: 0px;
    }
  
    .Noti_Karthick {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #35414D;
    }
  
    .Mins {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      color: #8F9CAA;
    }
  
    .R_F_C  {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #8F9CAA;
    }
  
    .Notify_Flex_Row_Col {
      display: flex;
      flex-direction: column;
      align-items:flex-start; 
      gap: 0px;
    }
  
    .Noti_U_T_2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #1B252F;
      display:flex;
    }
  
    .Content_H6 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color:#8F9CAA;
      margin-top: 10px;
      padding: 0px 10px;
      line-height: 1.3;
      white-space: nowrap; 
      width: 325px; 
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .Content_H6:hover {
    overflow: visible;
    white-space:normal !important;
  }

  .Reverse_User {
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
  }
  
    .D_Flex_A {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0px;
    }
  
    .display_None_Web_A {
      display: contents;
    }  
  
    .Res_Pub {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #35414D;
      display:contents;
    }
  
    .Back_Padd_Mar {
      border-bottom: 1px solid #E6ECF1;
      background-color: #ffffff;
      padding: 7px;
      margin-top: 5px;
    }
  
    .web-display-none {
      display: contents;
    }
  
    .School_Tooltip {
      left: 1%;
      max-width: 300px;
    }
  
    .School_Tooltip_Cube {
      display:flex;
      justify-content:flex-end;
      margin-right: 85%;
    }
  
    .ContentTooltip_Icon {
      left:15% !important;
      width:250px !important;
    }

    .Unit_Supp {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #2A004A;
      white-space: nowrap; 
      width: 200px; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }

    .Class_section {
      display: none;
    }

    .Margin_Right_25px {
      margin-right: -25px;
    }

    .ISk_logo_D {
      width: 100%!important;
    }

    .Back_Padd_Mar1 {
      border-bottom: 1px solid #E6ECF1;
      background-color: #ffffff;
      padding: 7px;
      margin-top: 5px;
    }

    .Units_Font {
      font-size:8px;
    }

    .Portions_covered {
      font-size: 8px;
    }

    .Clarify_Font {
      font-size: 8px;
    }

    .Y_T_Clarify {
      font-size:8px;
    }

    .BookOpen_Icon {
      width: 12px;
      height:12px;
    }

    .bookopenjai {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0px;
    /* padding: 7px 3px; */
    padding: 3px 3px 7px 3px;
    }

    .bokopenjai {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-right: 0px solid #8F9CAA;
      border-bottom: 0.5px solid #E8E8E8;
      width: 100%;
      align-items: center;
      padding-bottom: 5px;
    }

    .bookclarify2jai {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0px;
      width: 100%;
      padding-top: 2px;
    }

    .bookcoverd {
      display:flex;
      justify-content: center;
    }


    .Display_Mob_Contents {
      display: contents;
    }


    .Cla_Status {
      display: flex;
      justify-content: flex-start;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      color: #000000;
      width:100%;
      margin-top: 5px;
    }

    .green-bullet-sm {
      color: #06C270;
      font-size: 20px;
      margin-top: -2px;
    }

  .orange-bullet-sm {
    color: #FC5F37;
    font-size: 20px;
    margin-top: -2px;
  }
}
  
  /* Responsive design for Timetable css end here (Mobile)*/  
  
  
  
  /* Responsive design for Timetable css end here (Tablet)*/
  
  @media (min-width:481px) and (max-width:1200px) and (orientation:landscape) {
  
    .Header_Dashboard{
      display: none;
    }

    .Dash_home_QWI {
      margin-top: 120px;
    }
  
    .Flex_Row_Period {
      padding: 0px 0px 0px 17px
    }
  
    .Unit_Sup {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      color: #2A004A;
      white-space: nowrap; 
      width: 80px; 
      overflow: hidden;
      text-overflow: ellipsis; 
      padding-bottom: 5px;
    }
  
    .Unit_Color_sup{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      white-space: nowrap; 
      width: 80px; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .Eng_Video{
      width:12%;
      padding:5px;
    }
  
    .Mat_Video{
      width:11%;
      padding:5px;
    }
    
    .Phy_Video{
      width:11%;
      padding:5px;
    }
  
    .Bot_Video{
      width:11%;
      padding:5px;
    }
  
    .Zoo_Video{
      width:11%;
      padding:5px;
    }
  
    .Tam_Video{
      width:11%;
      padding:5px;
    }
  
    .Che_Video{
      width:11%;
      padding:5px;
    }
  
    .E_Font{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      color: #9B36EA;
    }
  
    .S_Font {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: #1B252F;
    }
  
    .Unit_Color_supp {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #66747F;
    }
  
    .Col_Gap {
      gap:8px;
    }
  
    .Notify_Design {
      position: absolute;
      right: 18%;
      width: 49%;
      height:300px;
      z-Index: 1;
      padding:15px;
      border-radius: 16px;
      background-Color: #ffffff;
      border: 1px solid #E6ECF1;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
      overflow-x:auto;
    }
  
    .Mins {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      color: #8F9CAA;
    }
  
    .Display_Notify_None {
      display: none;
    }
  
    .D_Flex_1 {
      margin-top: 0px;
    }
  
    .display_None_Web_A {
      display: none;
    }
  
    .Res_Pub {
      display: none;
    }
  
    .Back_Padd_Mar {
      border-bottom: 1px solid #E6ECF1;
    } 

    .Pad_Px {
      padding:5px 10px 10px 10px;
    }

    .Units_Font {
      font-size:9px;
    }

    .Portions_covered {
      font-size: 10px;
    }

    .Clarify_Font {
      font-size: 10px;
    }

    .Y_T_Clarify {
      font-size:10px;
    }

    .BookOpen_Icon {
      width: 12px;
      height:12px;
    }

    .bookcoverd {
      width: fit-content;
    }
    
    .bookopenjai {
      padding: 0px;
    }





  }
  
  /* Responsive design for Timetable css end here (Tablet)*/
  