.StudentAssignment__table th{
    border: 2px solid bisque;
    background-color : bisque;
    font-weight : bold;
    font-size :19px;
    text-align: center;
}
.StudentAssignment__table td{ 
    border:0.5px solid bisque;
    border-bottom: 2px solid bisque;
    cursor: pointer;
    text-align: center;
}

.StudentAssignment__tableElements:hover{
    background-color : aliceblue;
}

.StudentAssignment__tableStart{
    border-left:2px solid bisque;
}

.StudentAssignment__tableEnd{
    border-right:2px solid bisque;
}

.border-d8d8d8{
    border : 1px solid #d8d8d8;
  }

  .border-top-d8d8d8{
    border-top :1px solid #d8d8d8;
  }

  .StudentAssignment__exam-title{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #2684ff;
    /* text-transform: uppercase; */
    /* letter-spacing: 1; */
  }

  .paddingBottom-50 {
  padding-bottom: 50px!important;
}
