.required-field::before {
    content: "*";
    color: red;
    float: right;
    padding-left: 2px;
  }

.width-fit{
    width : fit-content;
}

.KInput__input{
  height : 38px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 2px 8px;
}

.KInput__input:focus {
    outline-color: #2684ff !important;
}