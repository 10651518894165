@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');



/* login :start here */

.kampus_Main_Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../Assets/login_bg.jpg') center center/cover no-repeat fixed;
}

.Login_logoImg {
  width: 13%;
}

#loginform{
  width: 30%;
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  border-radius: 8px;
  font-family: 'Inter';
  opacity: 0.9;
}

.ExamBtn{
  background-color: green;
    color: rgb(255, 255, 255);
    padding: 6px 26px!important;
    border-radius: 30px;
}

#headerTitle{
  text-align: center;
  font-family: 'Inter';
  padding: 1rem 0;
  margin: 0;
  font-size: 25px;
  font-weight: 900;
  color: #1e3164;
  letter-spacing: 0.5px;
}

.login_Inp_card {
  margin: 0px 0px!important;
  padding: 10px 25px;
}

.login_Inp_card{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  max-width: 100%;
  padding-bottom: 20px;
}

.login_Inp_card input{
  box-sizing: border-box;
  border: none!important;
  font-size: 14px;
  padding-left: 0px;
  padding-bottom: 10px;
  box-shadow: inset 0px -1px 0px 0px rgb(187 187 187 / 20%);
  transition: box-shadow 0.2s ease-in;
  border-bottom: 1px solid #ccc!important;
  margin-top: 5px;
}

.login_Inp_card input:focus{
   box-shadow: none!important;
   outline: none!important;
   border-bottom: 1px solid #3377FF!important;
}

.login_Inp_card input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.login_Inp_card input:hover::-webkit-input-placeholder,
.login_Inp_card input:focus::-webkit-input-placeholder{
  opacity: 0;
}

.login_Inp_card label{
  align-self: start;
  /* padding-bottom: 0.5rem; */
  /* color: rgba(187,187,187,0.9); */
  padding-left: 0px;
  color: #1e3164;
  font-weight: 500;
}

.logBtn_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.loginBtn_Qit{
  border-radius: 21px;
  color: white;
  font-weight: 700;
  background: #3377FF;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  padding: 8px 40px;
  font-family: inter;
  font-size: 17px;
}

.loginBtn_Qit:hover{
  /* opacity: 0.8; */
  background-color: #2960ce;
}


/* login :end here */

/* start fetch book clarify and unclarify count jai */

.bookopenjai {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  padding: 7px 3px;
}

.bokopenjai {
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-right: 1px solid #8F9CAA;
  width: 100%;
}

.bookcoverd {
  padding-top: 0px;
}

.bookclarify2jai {
  display: flex;
  flex-direction: column;
  gap:0px;
  width: 100%;
}

.Units_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #66747F;
}

.Portions_covered {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #8F9CAA;
}

.Clarify_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #8F9CAA;
}

.Y_T_Clarify {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #8F9CAA;
}

/* end fetch book clarify and unclarify count jai */


/* Dashboard Page css */


.D_Flex_Chapter {
display: flex;
flex-direction: row;
justify-content: space-between;
}

.h5_Eng {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #1B252F;
}

.P_Unit_S {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #66747F;
}

.Session_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #3377FF;
}

.clarify_dot {
  height: 7px;
  width: 7px;
  background-color: #B8C2CC;
  border-radius: 50%;
  display: inline-block;
  transform: rotate(-0.19deg);
}

.D_Flex_Div {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  gap:15px;
}

.Mon_Apr {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 17px;
color: #1B252F;
}


.D_Flex_Time {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  gap:15px;
  border-bottom: 1px solid #E6ECF1;
  padding-bottom: 15px;
}

.D_Flex_M_Profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0px 0px 0px;
}

.Meet_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ADB9C7;
}

.Meet_Time_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #35414D;
}

.Meet_Manoj_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #35414D;
}

.Meet_E_T_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ADB9C7;
}

.Img_Gap {
  display:flex;
  align-items:center;
  gap:10px;
}

.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 400px;
  height: 48px;
  background: #3377FF;
  border-radius: 30px;
  margin-top: 30px;
  border: none;
}

.Button_J_M {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

.Button_Center {
  display: flex;
  justify-content: center;
}


.float-leftmOBI {
  float: left!important;
  margin-top: 30PX;
}

.float-left {
  float: left!important;
}
.float-right {
  float: right!important;
}

.Time_Apr {
  display: block;
  display:flex;
  justify-content: space-between;
  align-items:center;
}

.Time_Aprmobi{
  display: none;
}

.Time_Table {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #1B252F;
  letter-spacing: 1;
  padding:1px 10px 0px 10px;
}

.Time_Table_BirthDay {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #1B252F;
  letter-spacing: 1;
  /* margin-top: 25px; */
  padding: 30px 10px 0px 10px;
}

.Apr_22 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #66747F;
  letter-spacing: 1;
}

.column_Box {
  padding: 15px;
}

.column_Boxx {
  padding-left: 0px;
  padding-right: 0px;
}

.DateWise_tableData {
  height: 490px;
  overflow-y: scroll;
  padding-top: 10px;
}

.DateWise_tableData::-webkit-scrollbar {
  display: none;
}

.DateWise_tableData {
-ms-overflow-style: none; 
scrollbar-width: none; 
}

.Background_Color {
  /* height: 580px; */
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  /* overflow-y: scroll; */
  }


.Background_Color::-webkit-scrollbar {
    display: none;
}

.Background_Color {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.Day {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ADB9C7;
}

.Date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ADB9C7;
}

.Tue_Day {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1B252F;
}

.Tue_Date {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: #FFFFFF;
    background-color: #3377fe;
    /* width: 35px;
    height: 35px; */
    border-radius: 50%;
    /* padding: 6.5px; */
    padding: 6px 7px;
    display: flex;
    align-items: center;
    width: 35px;
    justify-content: space-around;
    margin-left: 15%;
}

.Day_Date {
    /* margin: 10px 30px 10px 80px;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: -15px; */
    margin: 10px;
    text-align: center;
    margin-bottom: 0px;
}

.timeTable_Home_scrn {
  margin-top: 100px;
  margin-bottom: 60px;
}

.schoolName {
  font-weight: 600;
  color: #000000;
  letter-spacing: 1px;
  font-size: 19px;
}

.timeTable_Home_scrns {
  margin-top: 0px;
  height: 470px;
  overflow-y: auto;
}

.timeTable_Home_scrns::-webkit-scrollbar {
  display: none;
}

.timeTable_Home_scrns {
-ms-overflow-style: none; 
scrollbar-width: none; 
}

  .D_Flex{
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
.Time {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ADB9C7;
  padding: 0px 0px 0px 10px;  
  width: 90px;
  margin-top: -8px;
}

.instructsTxt {
  box-sizing: border-box;
  margin: 20px;
  font-family: 'Inter';
  text-align: justify;
  font-size: 18px;
}
.instructsTxt li {
  margin-bottom: 15px;
}

.English_Time {
  width: 94%;
  height: 80px;
  border: 1px solid #EEF2F5;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px 1px 1px 3px;
  padding: 8px 15px 0px 15px;
  margin-left: 2%;  
  margin-top: 5px;
}

.English_TimeMobi {
  width: 63%;
  height: 175px;
  border: 1px solid #EEF2F5;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px 1px 1px 3px;
  padding: 8px 15px 0px 15px;
  margin-left: 2%;  
  margin-top: 5px;
}

.D_F_J_S {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:0px 30px 0px 10px;
}

.Eng {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: black;
}

.AM_Time {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* margin-left: 10px; */
}

.AM_TimeMobi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.Unit_supplementarty {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2A004A;
}

.D_FLex_Unit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Cath_F_S {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #35414D;
  margin-left: 10px;
}
/* Dashboard Page css */

/* Header Navbar css */

.navbar-container{
    padding: 5px 10px;
    display:flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    position: fixed;
    top:0;
    width: 100%;
    border-bottom: 1px solid #EEF2F5;
    z-index: 9;
  }

  .Header_Dashboard {
    display: flex;
        align-items: center;
        justify-content: center;
        /* display:none; */
  }

  .web-display-none {
    display:none
  }

  .navbar-title {
    color: #3377FF;
    font-size: 23px;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-family: 'ubuntu';
  }

  /* .navbar-title{
    color: #9B36EA;
    font-size: 23px;
    font-weight: 700;
    font-family: 'Inter';
    display: flex;
    align-items: center;
  } */

  .cursor-hover:hover {
    cursor: pointer;
  }

  .dropdown-profile {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }

  .mob-display-none {
    display: contents;
  }

  .dropdown-profile-hover:hover{
    background-color: aliceblue;
    cursor: pointer;
    border-radius: 50%;
  }

  .profile-dropdown-container{
    /* position: absolute; */
    position: fixed;
    right: 10.5%;
    width: 200px;
    z-index: 9999999999;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 16%);
    top: 80px;
  }

  .dropdown-profile-title{
    font-size: 16px;
    font-weight: 700;
    padding: 3%;
    text-align: center;
    border-bottom: 1px solid #838383;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Inter",sans-serif;
  }

  .Class_section {
    font-size: 12px;
    color: rgb(143, 156, 170);
  }

  .M_Right {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }


  .dropdown-profile-sub-title-name {
    padding-left: 3%;
    font-weight: 400;
  }

  .dropdown-profile-sub-title-name {
    padding-left: 3%;
    font-weight: 400;
  }



  .dropdown-profile-sub-title-name {
    padding-left: 3%;
    font-weight: 400;
  }

  .School_Tooltip {
    max-width: 250px;
    text-align: center;
    position: absolute;
    right: 20px;
    display: flex;
    flex-direction: column;
    z-index: 99999999999;
    top: 55px;
}

.School_Tooltip_Cube {
    display:flex;
    justify-content:flex-end;
    margin-right: 16px;
}


.Dash_Icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* color: #3377FF; */
  cursor: pointer;
}
.pswd_toggle {
  position: relative;
  float: right;
  margin-top: -55px;
  margin-right: 39px;
  z-index: 999999;
  color: #3377ff;
  cursor: pointer;
}

.Book_Icon {
  display: flex!important;
  flex-direction: row;
  align-items: center;
  padding: 0px!important;
  gap: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #8F9CAA;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: auto!important;
}

.nav-link .menu_icon {
  /* margin-right: 5px; */
}

.Video_Call_Interface {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding:25px;
  background-color: #fff;
}

.D_Flex_Chapter {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}

.h5_Eng {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #1B252F;
}

.P_Unit_S {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #66747F;
}

.Session_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #3377FF;
}

.clarify_dot {
  height: 7px;
  width: 7px;
  background-color: #B8C2CC;
  border-radius: 50%;
  display: inline-block;
  transform: rotate(-0.19deg);
}

.D_Flex_Div {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  gap:15px;
  margin-top: 10px;
}

.Mon_Apr {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 17px;
color: #1B252F;
}


.D_Flex_Time {
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  gap:15px;
  border-bottom: 1px solid #E6ECF1;
  padding-bottom: 15px;
  margin-top: 10px;
}

.D_Flex_M_Profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0px 0px 0px;
}

.Meet_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ADB9C7;
}

.Meet_Time_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #35414D;
}

.Meet_Manoj_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #35414D;
}

.Meet_E_T_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ADB9C7;
}

.Img_Gap {
  display:flex;
  align-items:center;
  gap:10px;
}

.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 400px;
  height: 48px;
  background: #3377FF;
  border-radius: 30px;
  margin-top: 30px;
  border: none;
}

.meeting_avail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 400px;
  height: 48px;
  background: #3377FF;
  border-radius: 30px;
  margin-top: 30px;
  border: none;
}

.meeting_disable {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 10px;
width: 400px;
height: 48px;
background: rgb(204, 204, 204);
border-radius: 30px;
margin-top: 30px;
border: none;
}

.Button_J_M {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

.Button_Center {
  display: flex;
  justify-content: center;
}

/* Header Navbar css */

/* notieboard jai start */

  .noticecardjai::-webkit-scrollbar {
    display: none;
}

.classNotice{
  height: 580px;
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow: auto;
  margin-top: 10px;
  padding: 0px 15px 0px 15px;
}

.maritopGin{
  margin-top: 0px;
}

.filteryuio{
  top: 20%;
  left: 68%;  
}

.maritopGinq{
  margin-top: 18px;
}

.classNotice::-webkit-scrollbar {
  display: none;
}

.classNotice {
-ms-overflow-style: none; 
scrollbar-width: none; 
}

.classPadding{
  padding: 20px 0px;
}


.padAbsent17{
  padding-left: 40px;
}


.padlef17{
  padding-left: 5px;
}

.noticecardjai {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}
  
.datenotice{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #66747F;
  gap: -50px;
  }
  
.datebelownotice{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #1B252F;
}
  /* notieboard jai end */

  
/* attendnece jai :start */

.myattendcard{
  height: 380px !important; 
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 15px 25px;
}

.myattendcardteach{
  height: 780px !important; 
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 15px 15px;
  margin: 10px 10px;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.myattendcardteach::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.myattendcardteach {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.marfgijn{
  padding-right: 15px !important;
}

.myattendcard::-webkit-scrollbar {
    display: none;
}

.myattendcard {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.attnheig{
  height: 459px;
}

.attenpara{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: #ADB9C7;
}

.attennumber{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 120%;
color: #1B252F;

}


.paraNumbwer{
  padding-left: 48px;
}

.attenpadapada{
  padding-left: 15px;
}

.dateaten{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 150%;
text-align: right;
color: #66747F;
padding-top: 10px !important;
padding-left: 7px !important;
}

.green-bullet-sm{
  color: #06C270;
  font-size: 20px;
  margin-top: -5px;
}

.orange-bullet-sm{
  color:#FC5F37;
  font-size: 20px;
  margin-top: -5px;
}

.mcqmyProgress{
  width: 30% !important;
  background-color: #E5E5E5 !important;
  border-radius: 0px 0px 30px 30px !important;
}

.mcqmyBar{
  height: 20px !important;
  border-radius: 50px !important;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%) !important;
}

.barhori{
  transform: rotate(90deg) !important;
  margin-top: 500px;
}

.barboriee1{
  width: 20px;
  height: 301px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee2{
  width: 20px;
  height: 251px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee3{
width: 20px;
height: 286px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
border-radius: 30px 30px 0px 0px;
}

.barboriee4{
width: 20px;
height: 338px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee5{
width: 20px;
height: 301px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee6{
width: 20px;
height: 323px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee7{
width: 20px;
height: 294px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee8{
width: 20px;
height: 270px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee9{
width: 20px;
height: 230px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee10{
  width: 20px;
  height: 230px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee11{
  width: 20px;
  height: 230px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.janatten{
  
width: 25px;
height: 21px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 150%;
color: #8F9CAA;

}
.febatten{
  
  width: 25px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
  
}
.maratten{
  
  width: 26px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}
.apratten{
  
  width: 24px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}
.mayatten{
  
  width: 29px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}
.junatten{
  
  width: 25px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}
.julatten{
  
  width: 20px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
  
}
.augnatten{
  
  width: 27px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}
.sepatten{
  
  width: 26px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}
.octatten{
  
  width: 24px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}
.novatten{
  
  width: 27px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.decatten{
  
  width: 27px;
  height: 51px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

/* attendnece jai end */


/* slide start jai */


.css-hn784z button {
  background-color: black;
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
  opacity: 0.4;
}

.css-1abc02a button {
  background-color: black;
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
  opacity: 0.4;
}

.css-1m9128y {
  position: absolute;
  top: 87%;
  left: 40%;
  width: 100%;
  z-index: 2222;
}

.padFirsLef{
  padding-left: 0px !important;
}

.padFirsLef2{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.paraimg_fir{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #aed6ff;
  position: absolute;
  top: 215px;
  left: 20px;
  z-index: 999;

}

.paraimg_sec{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 120%;
color: #FFFFFF;
position: absolute;
  top: 240px;
  left: 20px;
  z-index: 999;

}

.paraimg_thi{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
color: #FFD426;
position: absolute;
  top: 270px;
  left: 20px;
  z-index: 999;
}

  .examinmghj{
    width: 100%;
    height: 345px;
    min-height: 345px;
  }

  
.slidecarosoul{
  border-radius: 5px !important;
  overflow: hidden;
}
  /* slide end here jai */

  
  
/* Assigments start here */

.cardassignmnetsss{
  box-sizing: border-box;
  height: 612px;
background: #FFFFFF;
border: 1px solid #E6ECF1;
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
border-radius: 5px;
padding: 0px 20px;
}

.overfloADDIGNTEACH{
  height: 545px;
  overflow: auto;
}


.overfloADDIGNTEACH::-webkit-scrollbar {
  display: none;
}

.overfloADDIGNTEACH {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mathsassign{
width: auto;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
border-radius: 5px;
padding: 10px;
}

.Class_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #3377FF;
  cursor: pointer;
}

.Class_Font_1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #8F9CAA;
  cursor: pointer;
}

.Unit_Font_1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  color: #3377FF;
}

.Unit_Font_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  color: #8F9CAA;
}


.mathsassign1{
  width: 66px;
  height: 30px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #9B36EA;
background: #F5EAFD;
border-radius: 5px;
padding-top: 7px;
margin-left: 10px;
text-align: center;
}

.mathsassign2{
  width: 69px;
  height: 30px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #FC5F37;
background: #FFEEEA;
border-radius: 5px;
padding-left: 10px;
padding-top: 7px;
margin-left: 10px;

}

.mathsassign3{
  width: 65px;
  height: 30px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #3377FF;
background: #E3EDFF;
border-radius: 5px;
padding-left: 10px;
padding-top: 7px;
margin-left: 10px;

}

.mathsassign4{
  width: 84px;
height: 30px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #F68E13;
background: #FFF6D3;
border-radius: 5px;
padding-left: 10px;
padding-top: 7px;
margin-left: 10px;

}

.assign_date{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #66747F;
}

.kildate{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 120%;
color: #1B252F;
}

.maritb{
  margin: 22px 0px;
}

/* Assignments end here */

/* exam start here */

.engdateexa{
  width: 74px;
  height: 74px;
  background: #EEF2F5;
  opacity: 0.7;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
}

.engdatefirst{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 120%;
color: #1B252F;
}

.engdatesec{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #66747F;
}

.Border_Bottom{
  /* display:flex;
  flex-direction:column; */
  border-bottom: 1px solid #E6ECF1;
  /* padding:0px 0px 0px 0px; */
  width:100%;
}

.engsub{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 120%;
color: #1B252F;
}

.engsubdown{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
color: #ADB9C7;
margin-top: 5px;
}

.examcard{
  box-sizing: border-box;
  height: 612px;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 0px 27px;
}

.examcard::-webkit-scrollbar {
  display: none;
}

.examcard {
-ms-overflow-style: none; 
scrollbar-width: none; 
}
/* exam end here */

/* Dashboard Page css */

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {   
  background: #555; 
}

/* achivements */



.stylemyCarsou {
  height: 334px;
  width: 240px;
  padding-top: 20px;
  text-align: center;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-family: sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
  box-sizing: border-box;
}

.lementstyle {
  position: relative;
  height: 450px;
  width: 100%;
  display: flex;
  background: rgb(227, 237, 255);
  border-radius: 5px;
  flex: 1 1 0%;
}

.achiveCard{
  height: 612px;
}

.achiveimg{
  border-radius: 50%;
}

.titleimg{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 150%;
color: #1B252F;
text-transform: capitalize;
}

.pricelisty{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
text-align: center;
color: #66747F;
text-transform: capitalize;
}

/* achivements */

/* Event */
.add_event{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
color: #3377FF;
margin-bottom: 7px;
margin-right: 15px;
}

.eventcard{
  box-sizing: border-box;
  height: 660px;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 10px 0px;
}

.eventcard::-webkit-scrollbar {
  display: none;
}

.eventcard {
-ms-overflow-style: none; 
scrollbar-width: none; 
}

.titleevent{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 150%;
color: #1B252F;
}

.English_Time2 {
  width: 94%;
  height: auto;
  border: 1px solid #EEF2F5;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 1px 1px 5px;
  padding: 8px 15px 0px 15px;
  margin-left: 2%;  
  margin-top: 5px;
}

.Tilelelele{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
color: #1B252F;
}
/* Event */

/* exam result */
.ex_recard{
  box-sizing: border-box;
  height: 660px;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  /* margin: 20px 10px 0px 10px; */
  padding: 30px 20px;
}

.MariOExResul{
  margin-top: 40px;
}


.totex_re{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: #ADB9C7;
}

.totuni_re{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
color: #4C88FF;
}

.theeigex_re{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #1B252F;
}

.fivex_re{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #1B252F;
}

.lanex_re{
  width: 49px;
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: #8F9CAA;
}

.coverthreejai444 {
  height: 8px;
  border-radius: 50px;
  background-image:
    linear-gradient(to right, #fc4a1a, #f7b733);
}

.numex_re{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 150%;
text-align: right;
margin-left: 20px;
margin-top: -3px;
color: #1B252F;
}


.coverflexonejai {
    width: 50%;
    background-color: #E5E5E5;
    border-radius: 50px;
    height: 8px;
    margin-left: 35px;
    margin-top: 6px;
}

.coverthreejai111 {
  height: 8px;
  border-radius: 50px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
}

.barex_re{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.QuestionType_Options_list {
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 10px;
  z-index: 99999999;
  overflow: auto;
  width: 30%!important;
  height: auto;
  background-color: rgb(255, 255, 255);
  position: absolute;
  right: 0px;
  top: 112px;
}

.dropdown-profile-sub-title {
  font-size: 14px;
  padding: 2%;
  display: flex;
  flex-direction: row;
  padding-left: 10%;
  padding-top: 3%;
  padding-bottom: 3%;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-profile-sub-title:hover {
  background-color: #71A0FF;
  color: #fff;
  cursor: pointer;
}

.dropdown-profile-sub-title-name {
  padding-left: 3%;
  font-weight: 400;
}

/* exam result */

/* birthday */

.birthCard{
  height: 520px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 30px;
}

.ovescol::-webkit-scrollbar {
  display: none;
}

.ovescol{
  overflow-y: scroll;
}

.ovescol{
  -ms-overflow-style: none;
  scrollbar-width: none;  
}

.imgbirthCake{
width: 120px;
margin-top: -30px;
background: url(../../Assets/cakback.png);
}

.Textbirth{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 120%;
background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.imhtext{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
color: #1B252F;
}

.imhtext2{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: #8F9CAA;
}

.wishbith{
gap: 10px;
color: #fff;
height: 34px;
background: #3377FF;
border-radius: 30px;
margin-top: 5px;
padding: 4px 20px 0px 20px;
}

.gapimh{
  display: flex;
  align-items: center;
  gap: 15px;
}

.ipmics_img{
  vertical-align: middle;
  width: 45px;
  border-radius: 50px;
  height: 45px;
}

.wishbith2{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 120%;
margin-top: 12px;
color: #1B252F;
}
/* birthday */

/* footer */

.footpower{
width: 68px;
height: 18px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #8F9CAA;
}

.footyou{
width: 81px;
height: 16px;
font-family: 'Ubuntu';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
text-transform: capitalize;
color: #3377FF;
}

.allfoot{
width: 149px;
height: 18px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #ADB9C7;
padding-top: 10px;
}

.paddPower{
  padding-top: 5px;
}

.smacfoot{
width: 280px;
height: 40px;
background: #2E3A53;
box-shadow: 0px -4px 15px rgba(65, 68, 73, 0.18);
border-radius: 8px 8px 0px 0px;
z-index: 9999999;
}

.smacctextfoot{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #FFFFFF;
padding-left: 10px;
}

.bootbord{
  border: 1px solid #35414D !important;
}

/* footer */

/* Timetable design css Start here */

.Switch_Day_Week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 172px;
  height: 38px;
  background-color: #FAFCFF;
  border: 1px solid #E3EDFF;
  border-radius: 30px;
  cursor: pointer;
}

.Switch_Day {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  border-radius: 30px;
}

.Switch_Week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  background: #4C88FF;
  border-radius: 30px;
}

.Switch_Week_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
}

.Class_Period {
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  align-items: center;
}

.Flex_Row_Period {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0px 25px 0px 0px;
}

.Period_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #B8C2CC;
}

.Period_Time {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #ADB9C7;
}

.D_D_M {
  display: flex;
  flex-direction: column;
  /* width: 3.5%; */
}

.Day_Month {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ADB9C7;
  width: 44px;
}

.Eng_Video {
  width: 12%;
  /* height: 143px; */
  /* background-color: #FAF4FF; */
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #9B36EA;
  border-left-width: 2px;
  padding:10px;
}

.Display_Flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.E_Font{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #9B36EA;
}

.Unit_Sup {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2A004A;
}

.Unit_Supp {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2A004A;
}

.Unit_Color_sup {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #66747F;
}

.Unit_Color_supp {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #66747F;
}

.Mat_Video {
  width: 10%;
  /* height: 143px; */
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #06C270;
  border-left-width: 2px;
  padding:10px;
}

.S_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #1B252F;
}

.Phy_Video {
  width: 10%;
  /* height: 143px; */
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #FC5F37;
  border-left-width: 2px;
  padding:10px;
}

.Bot_Video {
  width: 10%;
  /* height: 143px; */
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #3377FF;
  border-left-width: 2px;
  padding:10px;
}

.Zoo_Video {
  width: 10%;
  /* height: 143px; */
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #3377FF;
  border-left-width: 2px;
  padding:10px;
}

.Tam_Video {
  width: 10%;
  /* height: 143px; */
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #FF3E84;
  border-left-width: 2px;
  padding:10px;
}

.Che_Video {
  width: 10%;
  /* height: 143px; */
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #F78E13;
  border-left-width: 2px;
  padding:10px;
}

.Mat_Video {
  width: 10%;
  /* height: 143px; */
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #06C270;
  border-left-width: 2px;
  padding:10px;
}

.Switch_Day_Blue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  background: #4C88FF;
  border-radius: 30px;
}

.Switch_Week_White {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  border-radius: 30px;
}

.Switch_Week_Font_Timetable {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #66747F;
}

.Switch_Day_Font_Timetable {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
}

.Dash_home_QWI {
  margin-top: 155px;
}

.Dash_home_QWI_std {
  margin-top: 110px;
}

.mob_web_display_none {
  display: none!important;
}

.M_D_W {
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:40px;
}

.B_Bottom {
  border-bottom: 1px solid #D6DDE6;
  width:100%;
}

.Sub_Flex{
  display:flex;
  flex-direction:column;
  width:100%;
}

.Row_Reverse {
  display:flex;
  flex-direction:row;
  align-Items:center;
}

.Video_Icon_Size {
  font-size:25px;
}

.F_Gap {
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:40px;
}

.Padding_Time {
  padding:25px 25px 0px 25px;
}

.F_J_C {
  display:flex;
  flex-direction:row;
  /* justify-content:space-evenly; */
  width:100%;
  gap: 12px;
}

  .Web_Display_None {
   display:none;
  }

  .Col_Gap {
    display:flex;
    flex-direction:column;
    gap:15px;
    margin-top:10px;
  }

  .Pad_Px {
    padding:15px 15px 10px 15px;
  }
  .Web_Cama_display_None {
    display:none;
  }

  .Switch_Day_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #66747F;
  }
  /* .Reverse_User {
    display: flex;
    flex-direction: row;
  } */

/* Timetable design css End here */




/* Responsive design for Timetable css start here (Mobile)*/

@media (min-width:281px) and (max-width:480px) and (orientation:portrait) {

  /* For Day style css  start here */
  .Time_Table {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1B252F;
  }

  .Apr_22 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #66747F;
  }

  .Header_Dashboard {
    /* display: none; */
  }

  .Time_Apr {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Switch_Day_Week {
    padding: 0px;
    width: 140px;
    height: 31px;
    border-radius: 30px;
    cursor: pointer;
  }

  .Switch_Day {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    gap: 10px;
    width: 82px;
    height: 30px;
    border-radius: 30px;
  }

  .M_D_W {
    display:flex;
    flex-direction:row;
    align-items:center;
  }

  .Day_Date {
    margin: 20px 0px 0px 0px;
    text-align: center;
  }

  .Day {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #ADB9C7;
  }

  .Date {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ADB9C7;
  }

  .Tue_Day {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #1B252F;
  }

  .Tue_Date {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #3377fe;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 4.5px;
    display: flex;
    align-items: center;
  }

  .B_Bottom {
    border-bottom: none;
  }

  .Time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ADB9C7;
    margin-top: 0px;
  }

  .Eng {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .AM_Time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    margin-left: 10px;
  }

  /* .Row_Reverse {
    display:flex;
    flex-direction:row-reverse;
    align-Items:center;
  } */

  .Video_Icon_Size {
    font-size: 20px;
  }

  .Unit_supplementarty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }

  .D_FLex_Unit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Cath_F_S {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #35414D;
    margin-left: 10px;
  }

  .F_Gap {
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:5px;
  }

  .Padding_Time {
    padding:25px 10px 0px 10px;
  }
  /* For Day style css end here */


  /* For Week style css start here */
  .M_D_W {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .Flex_Row_Period {
    display:none;
  }

  .Display_Flex {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    gap:10px;
  }

  .D_D_M {
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
    width: max-content;
  }

  .Day_Month {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #1B252F;
    /* width: 60px; */
    width: max-content;
  }

  .F_J_C {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    overflow-x: auto;
    gap:10px;
    width:100%;
    white-space: nowrap;
    padding: 0px 10px;
    background-color: #FFFFFF;
  }

  .F_J_C::-webkit-scrollbar {
    display: none;
  }

  .F_J_C {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .Eng_Video {
    min-width: 65%;
  }

  .Mat_Video {
    min-width: 65%;
  }

  .Phy_Video {
    min-width: 65%;   
  }

  .Bot_Video {
    min-width: 65%;
  }

  .Zoo_Video {
    min-width: 65%;
  }

  .Tam_Video {
    min-width: 65%;
  }

  .Che_Video {
    min-width: 65%;
  }

  .Web_Display_None {
    display:contents;
   }
  
  .Col_Gap {
    display:flex;
    flex-direction:column;
    gap:8px;
    margin-top:10px;
    margin-bottom: 10px;
  }

  .Pad_Px {
    padding:25px 0px 10px 0px;
  }

  .Web_Cama_display_None {
    display:contents;
  }
  /* For Week style css start here */

  .Meet_Time_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #35414D;
  }

  .Mon_Apr {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #1B252F;
  }

  .P_Unit_S {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #66747F;
  }

  .Session_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #3377FF;
  }

}

/* Responsive design for Timetable css end here (Mobile)*/



/* Responsive design for Timetable css end here (Tablet)*/

@media (min-width:481px) and (max-width:1200px) and (orientation:landscape) {

  .Header_Dashboard{
    display: none;
  }

  .Flex_Row_Period {
    padding: 0px 20px 0px 40px
  }

  .Unit_Sup {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #2A004A;
    white-space: nowrap; 
    width: 80px; 
    overflow: hidden;
    text-overflow: ellipsis; 
    padding-bottom: 5px;
  }

  .Unit_Color_sup{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    white-space: nowrap; 
    width: 80px; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Eng_Video{
    width:11%;
    padding:5px;
  }

  .Mat_Video{
    width:11%;
    padding:5px;
  }
  
  .Phy_Video{
    width:11%;
    padding:5px;
  }

  .Bot_Video{
    width:11%;
    padding:5px;
  }

  .Zoo_Video{
    width:11%;
    padding:5px;
  }

  .Tam_Video{
    width:11%;
    padding:5px;
  }

  .Che_Video{
    width:11%;
    padding:5px;
  }

  .E_Font{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #9B36EA;
  }

  .S_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #1B252F;
  }

  .Unit_Color_supp {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #66747F;
  }

  .Col_Gap {
    gap:8px;
  }
}

/* Responsive design for Timetable css end here (Tablet)*/
/* Attendance main */

.Navbar{
  /* overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
  height: auto; */
}
/* 
.myattendcard2w{
  height: 100%;
  background-color: red;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
} */

.borderboxAtten{
  box-sizing: border-box;
  /* width: 90%; */
  background-color: #FAFAFA;
  border: 1px solid #EEF2F5;
  border-radius: 8px;
  margin: 20px;
}

.borderbottmobi{
  box-sizing: border-box;
  width: 90%;
  height: 70;
  background-color: #FAFAFA;
border: 1px solid #EEF2F5;
border-radius: 8px;
margin-left: 5%;
margin-top: 11%;
}

.gapatten{
  padding: 20px;
}

.PROGRESS{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}


.janattenmobi{
  text-align: center;
width: 25px;
height: 21px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 9px;
line-height: 150%;
color: #8F9CAA;

}


.barboriee1{
  width: 20px;
  height: 201px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee2{
  width: 20px;
  height: 151px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee3{
width: 20px;
height: 186px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
border-radius: 30px 30px 0px 0px;
}

.barboriee4{
width: 20px;
height: 238px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee5{
width: 20px;
height: 201px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee6{
width: 20px;
height: 223px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee7{
width: 20px;
height: 194px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee8{
width: 20px;
height: 170px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee9{
width: 20px;
height: 130px;
background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
opacity: 0.2;
border-radius: 30px 30px 0px 0px;
}

.barboriee10{
  width: 20px;
  height:130px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee11{
  width: 20px;
  height: 150px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee12{
  width: 20px;
  height: 170px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.attenparamobi{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #ADB9C7;
}

.attennumbermobi{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 120%;
color: #1B252F;
}

.upheight{
  height: 30%;
}

.maririgh{
  /* width: 60%; */
  /* margin-left: 15%; */
  /* margin-top: 3%; */
}
/* Attendance main */

/* dark mode toggle */

.switch {
  position: relative;
  top: 5px;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.fa-moon {
  color: pink;
}

.fa-sun {
  color: yellow;
}

/* dark mode toggle end */


/* Responsive design for Mobile  */

.AM_Timemobi{
  display: none;
}

.schoolScrollMobi{
  display: none;
}

.viewall_class{
  display: none;
}

.classNoticeMobi{
  display: none;
}

.Time_Tablemobi{
  display: none;
}

.digcolumnRownaki{
  display: none;
}

.myattendcardMobi{
  display: none;
}

.calssTitleMobi{
  display: none;
}

.AssigTimeMObi{
  display: none;
}

.exschDahmobi{
  display: none;
}

.cardassignmnetsssMobi{
  display: none;
}

.examcardMobi{
  display: none;
}

.EvntTitle{
  display: none;
}

.MobiExaResu{
  display: none;
}

.flerojubet{
  display: none;
}

.Achive_Title{
  display: none;
}

.birthTitle{
  display: none;
}


.WebShownDesign{
  display: block;
  padding-left: 0px !important;
  padding-right: 0.1px !important;
  position: fixed;
  bottom: 0px;
  z-index: 99999;
}

.Mobile_ShowDesign{
  display: none;
}

.footerdash{
  display: contents;
  margin-top: 140px;
}

.webFoot{
  margin-top: 0px;
  background-color: #EEF2F5;
  height: 40px;
}

.Foot_erer{
  margin-top: 150px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: none;
  outline: none;
}

.headfix{
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.MbileShow{
  display: none;
}
/* Responsive design for Mobile  */



/* Style for Bookview List  */


.H_katbook {
  font-family: 'Inter';
  font-weight:600;
  font-size: 26px;
  color:black;
  /* font-weight:700; */
  /* Line-height:31.2px; */
}

.Card_Book_List {
  margin-top: 90px;
  /* height:270px; */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px!important;
  border: 1px solid #E6ECF1!important;
  position: inherit!important;
}


.Booklist_Subject {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 150%; */
  color:#1B252F;
  /* margin-top: 15px; */
}

.Container {
  display: flex;
  flex-wrap: wrap;
  
}

.Subject_container {
  padding: 20px 50px;
}

.Book_U_PC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 12px;
    font-family: 'Inter';
}

.BookOpen_Icon {
  width:16px;
  height:14px;
}

.Manoj_span {
  font-size:12px;
  font-weight:500;
}

.Margin_Left_Card {
  width: 260px;
  margin: 0px 5px;
}

.web-display-none {
  display:none
}

.Image_Size {
  width: 100%;
  height: 165px;
  margin-top: -55px;
  cursor: pointer;
}



.Portions_covered_Text_Container {
  width:45%;
}

.Units_Count_Container {
  width:38%;
}


.Pad_Mar {
   padding: 15px 20px 15px 20px;
    margin-top: 16px;
}

.Image_Size:hover {
  box-shadow: 1px 2px 22px 3px #dadad9;
  /* border: 1px solid #E6ECF1; */
}

.myProgress {
  width: 80% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
  float: left ;
}

.myBar {
  height: 10px !important;
  border-radius: 50px !important;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%) !important;
}


.grennmyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.grennmyBar {
  height: 10px !important;
  border-radius: 50px !important;
  /* background-image: linear-gradient(to right, #20BF55, #01BAEF) !important; */
  background: linear-gradient(180deg, #13DA84 0%, #189C63 100%);
}


.orangemyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.orangemyBar {
  height: 10px !important;
  border-radius: 50px !important;
  /* background-image: linear-gradient(to right, #FC575E, #F7B42C) !important; */
  background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
}

.purplemyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.purplemyBar {
  height: 10px !important;
  border-radius: 50px !important;
  /* background-image: linear-gradient(to right, #5F0A87, #A4508B) !important; */
  background: linear-gradient(180deg, #C780FF 0%, #8B3DC9 100%);
}

.pinkmyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.pinkmyBar {
  height: 10px !important;
  border-radius: 50px;
  background: linear-gradient(180deg, #FF7AAA 0%, #E62168 100%);
}

.Subject_container::-webkit-scrollbar {
  display: none;
}

.Subject_container {
-ms-overflow-style: none; 
scrollbar-width: none; 
}

.myprogress_pertg {
  font-size: 13px;
  font-family: 'Inter';
  padding-top: 5px;
  }

  .myprogress_pertg span {
    margin-left: 6%;
  }

  .Percent_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #35414D;
  }

/* Style for Bookview List  */



/* Responsive design for Mobile  */

@media (min-width: 280px) and (max-width:1100px) and (orientation: portrait) {

  .Margin_Left_Card {
    width: 47%;
  }

  .Booklist_Subject {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color:#1B252F;
  }

  .Subject_container {
    padding:10px 3px;
    width:100vw;
    height:100vh;
    overflow:auto;
  }

  .Units_Count_Text {
    margin-left: 25%;
  }
  
  .Manoj_span {
    font-size: 10px;
  }

  .myProgress {
    width:70% !important;
  }

  .Card_Book_List {
    margin-top: 50px;
    border-radius: 8px !important;
  }

  .myBar {
    height:7px !important;
  }

  .grennmyBar {
    height:7px !important;
  }

  .orangemyBar {
    height:7px !important;
  }

  .purplemyBar {
    height:7px !important;
  }

  .pinkmyBar {
    height:7px !important;
  }

  .Image_Size {
    width: 72px;
    height: 116px;
    margin-top: -25px;
  }

  .Book_U_PC {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    font-size: 9px;
    font-family: 'Inter';
  }

  .BookOpen_Icon {
    width:14px;
    height:14px;
  }
  
  .Pad_Mar {
    padding: 0px 5px;
     margin-top: 5px;
  }

  .web-display-none {
    display:contents;
  }

  .mob-display-none {
    display:none;
  }

  .School_Tooltip {
    left: 1%;
    max-width: 300px;
  }

  .School_Tooltip_Cube {
    display:flex;
    justify-content:flex-end;
    margin-right: 85%;
  }

  .ContentTooltip_Icon {
    left:15% !important;
    width:250px !important;
  }

  .font-12-px {
    font-size: 14px !important;
  }

  .Percent_Font {
    font-size: 10px;
    font-weight: 500;
  }

  .myprogress_pertg {
    font-size: 11px;
    font-family: 'Inter';
    padding-top: 5px;
  }
}

/* Responsive design for Mobile  */


/* Responsive design for Tablet  */
@media (min-width: 481px) and (max-width: 1200px) and (orientation: landscape) {

  .Margin_Left_Card {
    width: 23%;
  }


  .Units_Count_Container {
    width:45%;
  }
  
  /* .Image_Size {
    width: 85px;
    height: 130px;
  } */

  .Subject_container {
    padding:10px 20px 0px 20px;
    width:100vw;
    height:100vh;
    /* overflow:auto; */
  }

  .Portions_covered_Text_Container {
    width: 50%;
  }

  .Image_Size {
    height: 140px;
  }

  .Container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    padding: 10px 60px 30px 60px;
  }

   .BookOpen_Icon {
    width:14px;
    height:14px;
  }

  .Manoj_span {
    font-size: 12px;
  }

  .myProgress {
    width:70% !important;
  }

  .myBar {
    height:7px !important;
  }

  .grennmyBar {
    height:7px !important;
  }

  .orangemyBar {
    height:7px !important;
  }

  .purplemyBar {
    height:7px !important;
  }

  .pinkmyBar {
    height:7px !important;
  }

  .Card_Book_List {
    height:75%;
  }

  .Pad_Mar {
    padding: 5px 10px 5px 10px;
  }

  .myprogress_pertg {
    font-size: 10px;
    font-family: 'Inter';
    padding-top: 5px;
  }

}

/* Responsive design for Tablet  */


  /* Style for Notification  */

  .Notify_Design {
    position: absolute;
    right: 12%;
    width: 35%;
    height:300px;
    z-Index: 1;
    padding:15px;
    border-radius: 5px;
    background-Color: #ffffff;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    overflow-x:auto;
    top: 54px;
  }
  
  .Notify_Design::-webkit-scrollbar {
    display: none;
  }
  
  .Notify_Design {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .Notify_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #35414D;
  }
  
  .display_None_Web_A {
    display: none;
  }
  
  .LeftArrow_Notify {
    display: flex;
    gap: 10px;
    background-color: #ffffff;
    padding: 10px;
    position: fixed;
    width: 100%;
  }
  
  .Display_Notify_None {
    display: none;
  }
  
  .Res_Pub {
    display: none;
  }
  
  .Noti_Karthick {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #35414D;
  }
  
  .R_F_C {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8F9CAA;
  }
  
  .Mins {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8F9CAA;
  }
  
  .D_Flex_1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 0px;
  }
  
  .Class_XI{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ADB9C7;
  }
  
  .Content_H6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #35414D;
    margin-top: 10px;
    padding: 0px 60px 0px 71px;
    line-height: 1.3;
    white-space: nowrap; 
    width: 500px; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Content_H6:hover {
    overflow: visible;
    white-space:normal !important;
  }
  
  .Announcement_Noti {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #1B252F;
  }
  
  .Class_Holiday {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #35414D;
    padding: 10px;
  }
  
  
  .V_Clarify {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color:#3377FF;
    cursor:pointer;
  }
  
  .Noti_Bell {
      background-color:#E6ECF1;
      border-radius:50px;
      padding:12px;
      color:#8F9CAA;
      width:50px;
      height:50px;
  }
  
  .Noti_U_T_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #1B252F;
  }
  
  .View_R {
    font-family:'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #3377FF;
    margin-top:8px;
    cursor:pointer
  }
  
  .Notify_Flex_Row_Col {
    display: flex;
    flex-direction: row;
    align-items:center;
    gap: 15px;
  }
  
  .Back_Padd_Mar {
    border-bottom: 1px solid #E6ECF1;
    padding:"15px 0px 0px 0px !important"
  }
  
  /* Style for Notification  */

/* start footer KPOST chat css */

  .KpostIf_IyQ {
    position: fixed;
    right: 5px;
    bottom: 0px;
    z-index: 300;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
    background-color: transparent;
  }
  
  .Iframe_PItQ_X {
    height: 100vh;
    width: 500px;
    position: absolute;
    right: 10px;
    bottom: 0px;
    background-color: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    overflow: auto;
    padding: 0px 0px 0px 0px;
    z-index: 999999;
  }

  .Iframe_PItQ_X::-webkit-scrollbar {
    display: none;
  }
  
  .Iframe_PItQ_X {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* end footer KPOST chat css */

  .Cla_Status{
    display: none;
  }

  /* teeacher Dashboard */

  .Card_Teacher_classNotice{
    width: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    padding: 20px;
    /* margin-right:15px; */
  }

  .clascountteach{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .teach12{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 150%;
  }

  .colortchclas{
    color: #3377FF;
  }

  .overflowteach{
    overflow: auto;
    height: 320px;
    margin-top: 10px;
  }


  
.overflowteach::-webkit-scrollbar {
  display: none;
}

.overflowteach {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


  .teachclasdate{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #4C88FF;
  }

  .cardschooltech{
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E6ECF1;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: auto;
    /* height: 683px; */
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-right:10px */
  }

  
.cardschooltech::-webkit-scrollbar {
  display: none;
}

.cardschooltech {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

  .datetechschool{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;
color: #4C88FF;
margin-top: 15px;
  }

  .scholteachname{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 150%;
color: #1B252F;
  }

.imgteach{
  width: 460px;
height: 200px;
margin: 0px 0px;
}  

.paddingleftrihtteach{
  padding: 16px 15px;
}

.myatenteacher{
  margin: 10px 20px 0px 20px !important;
}

.charttaech{
  margin: 0px 20px 0px 20px !important;
}

.chartsecteach{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 150%;
color: #1B252F;
}

.padfhjiko{
  margin: 30px 50px 35px 30px;
}

.classdfgghjio{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 150%;
color: #ADB9C7;

}

.rowchar{
width: 80%;
height: 18px;
background: linear-gradient(180deg, #13DA84 0%, #189C63 100%);
border-radius: 30px;
}

.rowper{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
text-align: right;
color: #1B252F;
}

.chartSecrty{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin: 0px 60px;
}

.Chartbackjio{
  width: 80%;
height: 18px;
background: #A8EACD;
border-radius: 30px;
margin-left: 10px;
}

.Chartbackjiorange{
  width: 80%;
height: 18px;
background: #FEC7B9;
border-radius: 30px;
margin-left: 10px;
}

.Chartbackjioyeloo{
  width: 80%;
height: 18px;
background: #FFEDA6;
border-radius: 30px;
}

.Chartbackjioblue{
  width: 80%;
height: 18px;
background: #E3EDFF;
border-radius: 30px;
}


.rowcharorange{
  width: 90%;
  height: 18px;
  background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
  border-radius: 30px;
  }

.rowcharblue{
  width: 85%;
  height: 18px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  border-radius: 30px;
  }

.rowcharyello{
  width: 70%;
  height: 18px;
  background: linear-gradient(180deg, #FFE991 0%, #F2C925 100%);
  border-radius: 30px;
  }

  .Mothachart{
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .chartteachmobi{
    display: none;
  }

  .schollTitle{
    display: none;
  }


  /* Kampus New Milestone 2  */

  .SubjectStyle {
    width:250px;
    height: 230px;
    border-radius: 15px;
    border-color: black;
    display: flex;
    gap: 25px;
    flex-direction: column;
    border: 1px solid #dadada;
    /* padding: 20px 20px 0px 20px; */
    padding: 15px;
    cursor:pointer;    
  }

  .SubjectStyle:hover {
    border: 1px solid #adadad;
  }

  .borderColor {
    border:1px solid #d8d8d8;
    padding: 10px;
    border-radius: 15px;
  }

.DivBox {
    width:65%;
    /* border:1px solid #d8d8d8;
    padding: 10px; */
    padding: 15px;
    box-shadow: 0px 1px 6px rgb(0 0 0 / 7%);
    border-radius: 5px;
}

.DivBoxBig {
  /* width:65%; */
  border:1px solid #d8d8d8;
  padding: 10px;
  height: auto;
}

.borderCircleEdit {
  /* border: 1px solid #acacac; */
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  color: white;
  background-color: green;
  font-weight: 500;
}

.borderCircleDelete {
  /* border: 1px solid #acacac; */
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  color: white;
  background-color: red;
}

.Button-PaddingRight {
  padding-right: 60px;
}

.modal-content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 680px !important;
}

.ButtonSize {
  font-size: 18px;
}

.modal-header {
  border-bottom: none !important;
}

.tox:not([dir=rtl]) .tox-statusbar__branding {
  /* display: none !important; */
}

.tox-statusbar {
  display: none !important;
}


/* for ISCON Dashboard Css */

.DCenter {
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-around;
  /* gap: 50px; */
}

.Switch_Day_Week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 172px;
  height: 38px;
  background-color: #FAFCFF;
  border: 1px solid #E3EDFF;
  border-radius: 30px;
  cursor: pointer;
}

.Switch_Day {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  border-radius: 30px;
}

.Switch_Week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  background: #4C88FF;
  border-radius: 30px;
}

.Switch_Week_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #FFFFFF;
}

.Chart_Bar_Pie {
  text-align:center;
  padding:20px;
  margin-top:35px;
}


table, th, td {
  /* border: 1px solid black; */
  border-collapse: collapse;
}

th, td {
  padding:10px;
}

.CardStudentDetails {
  /* width:250px; */
  /* height:160px; */
  /* border: none; */
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 8px !important;
  float: left;
  margin-right: 0.4%;
}
.SingleStudentData {
display: flex;
padding: 40px 20px 10px 20px;
width: 100%;
justify-content: space-evenly;
box-shadow: rgb(0 0 0 / 8%) 0px 0px 8px !important;
border-radius: 15px;
}

.CardStudentDetailsViewMore {
  /* border-radius: 15px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 8px !important; */
  float: left;
  /* margin-right: 0.4%; */
}

.StudentYear {
  background-color: green;
  width: fit-content;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  color: white;
  text-align: center;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 600;
  }

.ViewMoreDetails {
  background-color: green;
  width: fit-content;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  font-weight: 600;
  height: 40px;
}

.ViewMoreBack {
  background-color: green;
  width: 130px;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  font-weight: 600;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/* .PercentComplete {
    width: 80px;
    height: 76px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding-top: 16px;
    border-radius: 10px;
    background-color: #ffffff;
} */

.PercentComplete {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
}

.PercentComplete_name {
  width: 110px;
  height: 90px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  padding-top: 16px;
  border-radius: 10px;
  background-color: #ffffff;
}

.ListIcon {
  color: black;
  /* background-color: black; */
  width: 40px;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid;
}

.ButtonDiv {
    width:160px;
    border-radius:10px;
    background-color:white;
    height:auto;
    position:absolute;
    text-align:center;
    z-index:999;
    margin-top:-30px;
    border: 1px solid #e0e0e0;
}

.Y_Button {
  width:110px;
  border-radius:5px;
  height:45px;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  color: black;
  border: 1px solid black;
}

.Y_Button:hover {
  border: 1px solid white;
  background-color: #000000;
  color: white;
}

.ButtonYears_Class {
  background-color: green;
  width: 100px;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  font-weight: 600;
}

.Sd_Video .video-react-video{
  border-radius: 15px;
}

.Sd_Video .video-react{
  border-radius: 15px;
  width: 100%!important;
}

.Sd_Video .video-react-control-bar{
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.Coverage_report_Color {
  background-image:linear-gradient(90deg, #649173 0%, #dbd5a4 100%); 
  /* height:100%;   */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  gap: 25px;
}

/* .video-react .video-react-big-play-button {
  margin-left: 45% !important;
  margin-top: 23% !important;
} */

.IskonLogoLap {
  width:40%;
}

.Y_Katbook_Cov {
  font-size:20px;
  color:#ffffff;
  font-weight:700;
}

.Name_Image {
  width:75%;
}

.Percent-Font {
  font-size:22px;
  font-weight:600;
}

.Flex_Gap {
  display:flex;
  flex-direction:column;
  align-items:center;
  gap:10px;
  width:110px;
  text-align:center;
}

.Flex_D_Percent {
  display:flex;
  flex-direction:row;
  justify-content: center;
  width: 100%;
}

.L_C_Font {
  font-size:15px;
  font-weight:500;
  text-align:center;
  color:white;
}

.Line_Height {
  line-height:18px;
}

.video-react .video-react-big-play-button {
  top: 44% !important;
  left: 51% !important;  
  border-radius: 50% !important;
  font-size: 2.5em !important;
  height: 1.5em !important;
  width: 1.5em !important;
}

.Col_Per_Name {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0px;
  align-items: center; 
}

.Percentage_Font {
  font-size: 22px;
  font-weight: 600;
}

.Covered_Font {
    font-size: 15px;
    font-weight: 500;
    width: 80px;
    text-align: center;
}

.Img_Name_Font {
    font-size: 15px;
    font-weight: 500;
    /* width: 80px; */
    width: 100px;
}

.Img_Name_Size {
  display: flex;
  flex-direction: row;
  /* padding-top: 25px;
  padding-left: 20px; */
  justify-content: space-evenly;
  width: 100%;
  gap: 0px;
  text-align: center;
  /* align-items: center; */
  /* padding: 25px; */
}

.Img_Name_Size_Font {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  padding-left: 20px;
  gap: 15px;
  text-align: center;
  align-items: center;
}

.Img_Name_Size_Font_3 {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: 0px;
  gap: 15px;
  text-align: center;
  align-items: center;
}

.Dashboard_Student_Pre_Board {
  /* border-right: 5px solid rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  padding: 2px;
  box-shadow: rgba(0, 0, 0, 0.04) 4px 0px 0px 0px;

}

.ButtonYears_Class_Student {
  background-color: green;
  width: 100px;
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  font-weight: 600;
  padding: 5px;
  text-align: center;
  margin-bottom: 15px;
}

.Height_List {
  height: 530px;
  overflow-y: auto;
}

.Height_List::-webkit-scrollbar {
  display: none;
}

table, th, td {
  border: 0.5px solid #a2a2a2!important;
  border-collapse: collapse!important;
}

.CardInnerDiv {
  display: flex;
  padding: 15px;
  justify-content:space-between;
  align-items:baseline;
}



/* Iskon Kampus for Mobile Responsive  */

@media (min-width:280px) and (max-width:480px) and (orientation:portrait) {

/* Iskon Kampus for Mobile Responsive  for Student Dashboard */

  .PercentComplete {
    width: 70px;
    height: 60px;
  }

  .Flex_Gap {
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:10px;
    width:70px;
    text-align:center;
  }

  .L_C_Font {
    font-size:11px;
    font-weight:600;
    text-align:center;
    color:white;
  }

  .Line_Height {
    line-height:12px;
  }

  .Flex_D_Percent {
    display:flex;
    flex-direction:row;
    gap: 15px;
    justify-content: center;
    width: 100%;
  }

  .video-react .video-react-big-play-button {
    top: 38% !important;
    left: 45% !important;  
    border-radius: 50% !important;
    font-size: 2.5em !important;
    height: 1.5em !important;
    width: 1.5em !important;
  }

/* Iskon Kampus for Mobile Responsive  for Student Dashboard */

/* Iskon Kampus for Mobile Responsive  for Teacher Dashboard */




/* Iskon Kampus for Mobile Responsive  for Teacher Dashboard */

}

/* Iskon Kampus for Mobile Responsive  */







/* Iskon Kampus for Tablet Responsive */

@media (min-width:481px) and (max-width:1200px) and (orientation:landscape) {


/* Iskon Kampus for Tablet Responsive  for Student Dashboard */

  .IskonLogoLap {
     width:65%;
  }

  .Y_Katbook_Cov {
    font-size:15px;
  }

  .Name_Image {
    width:50%;
  }

  .PercentComplete {
        /* width: 65px;
    height: 55px; */
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
}

.Percent-Font {
  font-size:13px;
  font-weight:600;
}

.Flex_Gap {
  display:flex;
  flex-direction:column;
  align-items:center;
  gap:10px;
  width:55px;
  text-align:center;
}

.Flex_D_Percent {
  display:flex;
  flex-direction:row;
  gap: 15px;
  justify-content: center;
  width: 100%;
}



.L_C_Font {
  font-size:12px;
  font-weight:600;
  text-align:center;
  color:white;
}

.Line_Height {
  line-height:15px;
}

/* Iskon Kampus for Tablet Responsive  for Student Dashboard */

/* Iskon Kampus for Tablet Responsive  for Teacher Dashboard */

.Percentage_Font {
  font-size: 16px;
  font-weight: 700;
}

.Covered_Font {
  font-size: 12px;
  font-weight: 700;
  width: 80px;
  text-align: center;
}

.Img_Name_Font {
  font-size: 13px;
  font-weight: 700;
  width: 80px;
}

.Img_Name_Size {
  display: flex;
  flex-direction: row;
  /* padding-top: 15px;
  padding-left: 20px; */
  /* padding: 0px 15px; */
  gap: 0px;
  text-align: center;
  /* align-items: center; */
}

.CardInnerDiv {
  display: flex;
  padding: 10px;
  justify-content:space-between;
  align-items:baseline;
}


.Img_Name_Size_Font {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: 20px;
  gap: 15px;
  text-align: center;
  align-items: center;
}


/* Iskon Kampus for Tablet Responsive  for Teacher Dashboard */


}

/* Iskon Kampus for Tablet Responsive */

.flash {
  animation-name: flash;
   animation-duration: 0.2s;
   animation-timing-function: linear;
   animation-iteration-count: infinite;
   animation-direction: alternate;
   animation-play-state: running;
}

@keyframes flash {
   from {color: red;}
   to {color: black;}
}

.SessionView {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(90deg, #f3f4f3 0%, #f7f7f7 100%);
  padding: 18px;
  border-radius: 10px;
}
.pickupBtn {
  width: 150px;
  line-height: 13px;
  text-align: center;
  cursor: pointer;
  background-color: green;
  padding: 10px;
  border-radius: 4px;
}
span.pickupTxt {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
.katL {
  line-height: 35px;
  cursor: pointer;
  justify-content: center;
  display: flex;
}
.katL img {
  width: 25%;
}
.dateDis {
  height: 55px;
  max-height: 100%;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(90deg, #f3f4f3 0%, #f7f7f7 100%);
}

